<template>
  <div id="displayCard">
    <v-card class="mb-12 myCollectionCard" min-height="85vh">
      <h1>{{ $t("navNotification") }}</h1>
      <notifications />
    </v-card>
  </div>
</template>

<script>
import PlatformLayout from "@/layouts/Platform";
import Notifications from "@/components/Common/Notifications";

export default {
  name: "NotificationsPage",
  components: { Notifications },
  created() {
    this.$emit(`update:layout`, PlatformLayout);
  }
};
</script>
