<template>
  <v-card elevation="0">
    <div class="notification" v-if="this.messages.length > 0">
      <vue-scroll>
        <div v-for="(item, i) in messages" :key="item.id">
          <v-lazy min-height="87px">
            <div class="notificationRow">
              <div class="notificationClick" @click="redirect(item)">
                <div id="notificationIcon" style="min-width: 100px">
                  <img :src="getIconPath(item.notificationType)" height="40px" width="40px" />
                </div>
                <div id="notificationText">
                  <p class="p2" v-html="item.message" style="white-space: initial"></p>
                </div>
                <div id="notificationTime" class="d-flex align-center flex-grow-0">
                  <img src="@/assets/icon_pack/16px/clock_gray.svg" />
                  <div>
                    <p class="p2" id="notificationDate">{{ formatDate(item.timestamp) }}</p>
                  </div>
                </div>
              </div>
              <div id="notificationDelete" @click="acknowledgeRead(item.id)">
                <v-btn icon><img height="14px" width="14px" src="@/assets/icon_pack/40px/Cancel.svg" /></v-btn>
              </div>
            </div>
          </v-lazy>
          <v-divider v-if="i !== messages.length - 1" />
        </div>
      </vue-scroll>
    </div>
    <vue-scroll v-if="this.messages && this.messages.length === 0 && !notificationEmptyTimeout">
      <div class="mt-5" v-for="(e, i) in 20" :key="i">
        <v-skeleton-loader type="list-item-avatar" class="mb-5"> </v-skeleton-loader>
        <v-skeleton-loader type="divider"> </v-skeleton-loader>
      </div>
    </vue-scroll>
    <v-alert
      v-if="this.messages && this.messages.length === 0 && notificationEmptyTimeout"
      type="info"
      color="primary"
      outlined
      class="ma-0"
    >
      {{ $t("notificationNoUnread") }}
    </v-alert>
  </v-card>
</template>

<script>
export default {
  name: "Notifications",

  data: () => ({
    connection: null,
    notificationEmptyTimeout: false
  }),
  mounted() {
    setTimeout(this.setRead(), 10000);
    setTimeout(() => (this.notificationEmptyTimeout = true), 1000);
  },
  beforeDestroy() {
    this.setRead();
  },
  computed: {
    notificationCount() {
      return this.messages.length;
    },
    messages() {
      return this.$store.state.notifications
        .filter((e) => e.notificationType < 20)
        .sort((a, b) => {
          return b.timestamp - a.timestamp;
        });
    }
  },

  methods: {
    formatDate(date) {
      let time = new Date(date);
      return time.toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit"
      });
    },
    getIconPath(notificationType) {
      let notificationIcons = [
        "",
        require("@/assets/icon_pack/40px/2_market_approval.svg"),
        require("@/assets/icon_pack/40px/3_market_disapproval.svg"),
        require("@/assets/icon_pack/40px/4_market_approval_Private.svg"),
        require("@/assets/icon_pack/40px/5_Private the on market artwork.svg"),
        require("@/assets/icon_pack/40px/10_AN rejects the update of transaction profile.svg"),
        require("@/assets/icon_pack/40px/11_The passport will expire after 3 month.svg"),
        require("@/assets/icon_pack/40px/12_The passport has expired.svg"),
        require("@/assets/icon_pack/40px/13_The premuim membershiwill expire after 3 month.svg"),
        require("@/assets/icon_pack/40px/14_The premuim membership has expired.svg"),
        require("@/assets/icon_pack/40px/15_The premuim membership has expired after 9 months.svg"),
        require("@/assets/icon_pack/40px/16_The premuim membership has expired after 1 years.svg"),
        require("@/assets/icon_pack/40px/17_File are deleted due to the expiration of premuim member.svg"),
        require("@/assets/icon_pack/40px/6_New Chatroom created.svg"),
        require("@/assets/icon_pack/40px/7_Seller Signed a contract.svg"),
        require("@/assets/icon_pack/40px/8_Payment completed.svg"),
        require("@/assets/icon_pack/40px/8_Payment completed.svg"),
        require("@/assets/icon_pack/40px/9_A Deal become Inactve.svg"),
        "",
        ""
      ];

      return notificationIcons[notificationType - 1];
    },
    acknowledgeRead(id) {
      const token = this.$session.get("token");
      const payload = JSON.stringify({ action: 1, token: token, id: id });
      this.$store.commit("readNotification", payload);
    },
    setRead() {
      let payload = JSON.stringify({ action: 3 });
      this.$store.commit("readNotification", payload);
    },
    redirect(item) {
      const type = item.notificationType;
      if (type >= 2 && type <= 3) {
        if (item.workid && item.workid !== "") {
          this.$router.push(`/platform/artwork/${item.workid}`);
          return;
        } else {
          this.$router.push("/platform/bazaar").catch((e) => e);
          return;
        }
      }
      if (type >= 4 && type <= 5) {
        if (item.workid && item.workid !== "") {
          this.$router.push(`/platform/mycollection/${item.workid}`);
          return;
        } else {
          this.$router.push("/platform/mycollection").catch((e) => e);
          return;
        }
      }
      if (type >= 6 && type <= 13) {
        this.$router.push("/platform/profile").catch((e) => e);
        return;
      }

      if (type >= 14 && type <= 18) {
        if (item.orderid && item.orderid !== "") {
          this.$router.push(`/platform/chat/${item.orderid}`);
          return;
        } else {
          this.$router.push("/platform/chat").catch((e) => e);
          return;
        }
      }
      return;
    }
  }
};
</script>
